import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledOption, StyledFilter } from './FilterStyles'


const Option = (props) => (<StyledOption {...{ ...props }} isActive={props.isActive}><span>{props.children}</span></StyledOption>)

export const Filter = props => {
    const { filterInfo, activeIndustries, activeFeatures, setIndustry, deleteIndustry, setFeature, deleteFeature, removeActiveCreative } = props;
    const isNewFilter = (filter, filtersList) => !filtersList.includes(filter);

    const [Toggle, setToggle] = useState(false)
    const onCategoryClick = () => setToggle(q => !q);

    const onFilterClick = (filterValue) => {

        if (filterInfo.filterName === 'Industry') {
            isNewFilter(filterValue, activeIndustries) ? setIndustry(filterValue, filterInfo) : deleteIndustry(filterValue);
        } else if (filterInfo.filterName === 'Features') {
            isNewFilter(filterValue, activeFeatures) ? setFeature(filterValue, filterInfo) : deleteFeature(filterValue);
        }
        removeActiveCreative();
    };

    const optionsList = filterInfo.optionsList.map((option, i) => {
        const isActiveCheck = option => activeIndustries.includes(option) || activeFeatures.includes(option);
        const isActive = isActiveCheck(option)

        return (
            <Option isActive={isActive} onClick={(e) => {
                e.stopPropagation();
                onFilterClick(option)
            }} key={i}>{option}</Option>
        )
    });

    return (
        <StyledFilter isActive={Toggle} onClick={() => onCategoryClick(filterInfo.filterName)}>
            <OptionsButton isActive={Toggle}>{filterInfo.filterName}</OptionsButton>
            <ul>
                {optionsList}
            </ul>
        </StyledFilter>
    );
};

const OptionsButton = styled.div`
  padding: 0 20px;
  font: 20px RobotoReg, sans-serif;

  &:after {
    content: "";
    display: inline-block;
    width: 35px;
    height: 14px;
    background: url("https://portfolio.loopme.com/media/drop_down_icon.png") 8px 0 no-repeat;
  }

  ${props => {
        if (props.isActive) {
            return `
        background-position: 8px -17px;
      `;
        }
    }}
  
  @media (min-width: 880px) {

  ${StyledFilter}:hover &:after { 
    background-position: 8px -17px;
    } 
  }


  @media (max-width: 880px) {

    font: 18px RobotoLight, sans-serif;

    &:after {
      position: absolute;
      top: 12px;
      right: 10px;
      display: inline-block;
      content: "";
      width: 30px;
      height: 30px;
      background: url("https://portfolio.loopme.com/media/mobile_assets/P_content_box_icon_open.png") -2px 0 no-repeat;
      background-size: 100%;
    }

    &:hover &:after {
      position: absolute;
      top: 12px;
      right: 12px;
      display: inline-block;
      content: "";
      width: 30px;
      height: 30px;
      background-position: 0;
    }

    ${props => {

        if (props.isActive) return `
      &:after {
      top: 10px;
      background: url("https://portfolio.loopme.com/media/mobile_assets/P_content_box_icon_close.png") -2px 2px no-repeat;
      background-size: 100%;
    }`;
    }}
  }
`;

