import React from 'react';
import styled from 'styled-components';
import { FlexImage } from '../../basics';

const Qr = (props) => {
    const { src } = props;
    return (
        <StyledQr >
            <p>Live QR code</p>
            <div>
                <FlexImage src={src} alt='QR code' />
            </div>
        </StyledQr>
    )
}

const StyledQr = styled.div`
      margin-top: 35px;

      &>div {
        width: 132px;
        height: 132px;
      }
      
  @media (max-width: 880px) {
    display: none;
  }
`;

export default Qr