
import styled from 'styled-components';


export const StyledFiltersList = styled.div`
position: relative;
background-color: #f4f4f4;
border-bottom: 1px solid #b4b4b4;

& > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
}

  @media (max-width: 880px) {
  display: ${props => props.isActive ? 'block' : 'none'};
  position: fixed;
  top: 8.5vh;
  left: 0;
  right: 0;
  z-index: 5;
  height: 100vh;
  background-color: #232323;
  border-bottom: black;
  color: white;
  & > div {
    display: block;
    /* height: 80vh; */
    height: 100%;
    overflow-y: scroll;
  }
}`;


export const StyledFilter = styled.div`
  padding: 23px 0;

&>ul {
  display: none;
}

&:hover {
  cursor: pointer;
  background-color: #e0e0e0;
  color: #ed2027;
}
@media (min-width: 880px) {

&:hover ul {
  position: absolute;
  top: 52px;
  /* top: 53px; old */
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  transform: translateX(-33%);
  width: 504px;
  padding-left: 0;
  background: rgba(0, 0, 0, 0.8);
  list-style: none;
  font: 18px RobotoLight, sans-serif;
  color: white;
}
}
@media (max-width: 880px) {
  padding: 15px 0;
  position: relative;
  border-bottom: 1px solid black;
  background-color: #232323;
  &:hover { 
    background-color: #232323;
    color: white;
  }

${props => {
    if (props.isActive) {
      return `
      padding-bottom: 0;
      span:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 13px;
        right: 8px;
        width: 40px;
        height: 40px;
        background: url("https://portfolio.loopme.com/media/mobile_assets/P_filter_menu_off.png") 1px -6px no-repeat;
        background-size: 100%;
      } 

      ul {
      display: block;
      width: 100%;
      padding-left: 0;
      margin: 15px auto 0 auto;
      background-color: #0f0f0f;
      list-style: none;
      font: 18px RobotoReg, sans-serif;
      color: white;
      }`;
    }
  }}
}`;




export const StyledOption = styled.li`
    position: relative;
    display: block;
    width: 212px;
    padding: 14px 30px 17px 10px;

    &:hover {
      background-color: rgba(237, 29, 36, 0.9);
    }

${props => {
    if (props.isActive) {
      return `
background-color:rgba(237, 29, 36, 0.9);

& > span:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 45%;
        right: 0;
        transform: translate(-50%, -50%);
        width: 35px;
        height: 21px;
        background: url("https://portfolio.loopme.com/media/Tick_on_icon.png") 8px 0 no-repeat;
      }
`;
    }
  }}

    @media (max-width: 880px) {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid #232323;
      font: 14px RobotoLight, sans-serif;

      span {
        margin-left: 20px;
      }
     
      &:hover {
      background-color: black;
    }

    ${props => {
    if (props.isActive) {
      return `
      color: #ed1d24;
      background-color: black;
      
      & > span:after {
        content: "";
        display: inline-block;
        position: absolute;
        right: -6px;
        width: 40px;
        height: 40px;
        background: url("https://portfolio.loopme.com/media/mobile_assets/P_filter_menu_on.png") 7px 2px no-repeat;
        background-size: 100%;
      }`;
    }
  }}
    }`;