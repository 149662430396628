import React, { forwardRef } from 'react';
import styled from 'styled-components';

const join = src => src.join(', ');

const TextInformation = forwardRef((props, ref) => (
    <StyledTextInformation ref={ref}>
        <div>
            <CreativeInfo>{props.name}</CreativeInfo>
        </div>
        <div>
            <Title>Features</Title>
            <StyledP>{join(props.features)}</StyledP>
        </div>
        <div>
            <Title>Platform</Title>
            <StyledP>{join(props.platform)}</StyledP>
        </div>
        <div>
            <Title>Compatibility</Title>
            <StyledP>{join(props.compatibility)}</StyledP>
        </div>
    </StyledTextInformation>
));

const StyledP = styled.p`
  padding: 0;
  margin: 5px 0;
`;

const StyledTextInformation = styled.div`
  padding-top: 25px;
  padding-left: 15px;

  @media (max-width: 880px) {
  padding: 0 20px 10px 20px;  
  }`;

const Title = styled(StyledP)`
  margin-top: 25px;
  text-decoration: underline;

  @media (max-width: 880px) {
    margin-top: 8px;
  }`;

const CreativeInfo = styled(StyledP)`
  display: inline-block;
  padding: 7px 10px;
  background-color: #ed2027;
  text-align: center;
  font-family: RobotoReg, sans-serif;
  font-size: 18px;
`;

export default TextInformation