import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';

const Video = styled.video`
    position: absolute;
    top: -24px;
    left: -43px;
    z-index: 1;
    width: 385px;
    height: 600px;
`;


const PhoneBorder = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url('https://portfolio.loopme.com/media/Device_image.png') no-repeat;
`;


const ClickToPlay = styled.div`
  position: absolute;
  top: 220px;
  left: 115px;
  z-index: 4;
  width: 67px;
  height: 67px;
  background: url('https://portfolio.loopme.com/media/playbutton_icon.png') no-repeat 0 0;

  display: ${props => props.show ? "block" : "none"};

  &:hover {
    cursor: pointer;
    background-position: 0 -66px;
  }`;

const CreativeScreenshot = styled.img`
    position: absolute;
    top: -24px;
    left: -38px;
    z-index: 3;
    width: 375px;
    height: 600px;

    display: ${props => props.show ? "block" : "none"};
`;

const View = styled.div`
  position: relative;
  width: 302px;
  height: 600px;
  @media (max-width: 880px){
    display: none;
  }`;


export const VideoView = forwardRef((props, ref) => {
    const video = React.createRef();
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);

    const toggleVisibilityVideoElements = () => {
        setIsVideoPlaying((s) => !s.isVideoPlaying);
    }

    const onScreenShotClick = e => {
        video.current.play();
        !isVideoPlaying && toggleVisibilityVideoElements();
        setIsVideoPlaying(true);
    }

    const onVideoClick = e => {
        video.current.pause();
        isVideoPlaying && toggleVisibilityVideoElements();
        setIsVideoPlaying(false);
    }

    const onVideoEnded = e => {
        toggleVisibilityVideoElements();
        setIsVideoPlaying(false);
    }

    const { name, videoScreenshot, videoUrl } = props.adInfo;

    return (
        <View ref={ref}>
            <ClickToPlay
                show={!isVideoPlaying}
                onClick={onScreenShotClick}
            />
            <CreativeScreenshot

                show={!isVideoPlaying}
                alt={`${name} creative screenshot`}
                src={videoScreenshot}
                onClick={onScreenShotClick}
            />
            <PhoneBorder />
            <Video
                muted
                playsInline
                autoPlay
                webkit-playsinline='true'
                x-webkit-airplay='allow'
                ref={video}
                onClick={onVideoClick}
                onEnded={onVideoEnded}>
                <source src={videoUrl} type='video/mp4' />
            </Video>
        </View>
    )
})