import React from 'react';
import styled from 'styled-components';
import { FlexImage } from './basics'

export const Banner = ({ className }) => (
    <header className={'banner ' + className}>
        <LogoWrapper>
            <Logo >
                <FlexImage src='./media/logo.png' alt='LoopMe logo' />
            </Logo>
            <Title>AD Live Portfolio</Title>
        </LogoWrapper>
    </header>
);


const Title = styled.div`
  width: 286px;
  margin: 0 auto;
  padding: 10px 0;
  transform: translateX(-13%);
  background-color: rgba(237, 29, 36, 0.8);
  text-align: center;
  text-transform: uppercase;
  font: 22px RobotoMedium, sans-serif;
  color: white;

@media (max-width: 880px) {
  width: 155%;
  padding: 7px 5px;
  transform: translateX(-19%);
  font: 16px RobotoMedium, sans-serif;
  letter-spacing: 2px;
}`;

const Logo = styled.figure`
  display: block;
  width: 195px;
  margin: 55px auto 0 auto;

@media (max-width: 880px) {
  width: 100%;
  margin: 35px auto 0;
}`;

const LogoWrapper = styled.div`

z-index: 1;
width: 210px;
height: 260px;
margin: auto;
background-color: rgba(232, 3, 0, 0.2);
border: 1px solid rgba(247, 237, 239, 0.4);

@media (max-width: 880px) {
  width: 31vw;
  height: 50vw;
  background-color: rgba(232, 3, 0, 0.3);
}`;

const StyledBanner = styled(Banner)`
  display: flex;
  width: 100%;
  height: 50vh;
  border-top: 4px solid #ed1d24;
  background: url('https://portfolio.loopme.com/media/full_Screen_BG.jpg') center top no-repeat;
  background-size: auto 624px;

  @media (max-width: 880px) {
    height: 324px;
    background-size: auto 324px;
}`;

export default StyledBanner;