import React from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import Creative from './Creative';
import CreativeView from './CreativeView';


export const CreativeList = (props) => {

    const {
        adsList,
        activeIndustries,
        activeFeatures,
        activeCreative,
        rowsCount
    } = props;
    let position = null;
    let creativeView = null;
    const passFilters = ({ industry, features }) => {
        const isContain = (creativeFilters, activeFilters) => activeFilters.some(filter => creativeFilters.includes(filter));
        const isCorrespondsToIndustry = activeIndustries.length ? isContain(industry, activeIndustries) : true;
        const isCorrespondsToFeatures = activeFeatures.length ? isContain(features, activeFeatures) : true;
        const isCorrespondsToFilters = isCorrespondsToIndustry && isCorrespondsToFeatures;
        return (activeIndustries.length || activeFeatures.length) ? isCorrespondsToFilters : true;
    };

    const isActiveCreative = ({ creativeId }) => creativeId === activeCreative;
    const makeCreative = (adInfo, key) => <Creative  {...props} adInfo={adInfo} key={key} />;
    const makeCreativeView = (adInfo, key) => <CreativeView  {...props} adInfo={adInfo} key={`${key}View`} />;

    const getPosition = (key) => {
        let position = rowsCount;
        key += 1;
        const remainder = key % rowsCount;
        if (key > rowsCount) {
            position = remainder === 0 ? key : key + (rowsCount - (remainder));
        }
        return position;
    };

    let creatives = adsList
        .filter(passFilters)
        .reduce((acc, adInfo, key) => {
            acc = acc.concat(makeCreative(adInfo, key));
            position = isActiveCreative(adInfo) ? getPosition(key) : position;
            creativeView = isActiveCreative(adInfo) ? makeCreativeView(adInfo, `${key}active`) : creativeView;
            return acc;
        }, []);

    const insertCreativeView = (list, position, element) => list.splice(position, 0, element);
    insertCreativeView(creatives, position, creativeView);

    creatives = creatives.length > 0 ? creatives : <NoResults>No results</NoResults>;

    return (
        <StyledCreativeList
            easing='ease-in-out'
            appearAnimation={true}
            duration={250}
            delay={150}
        >
            {creatives}
        </StyledCreativeList>
    )
}

const NoResults = styled.div`
  font: 20px RobotoReg, sans-serif;
`;

const StyledCreativeList = styled(FlipMove)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1260px;
  padding-top: 70px;
  margin: 0 auto;
    &:empty + .load-more {
        display: none;
    }
    
    @media (max-width: 860px) {
        justify-content: center;
    }
    
    @media (max-width: 880px) {
        padding: 6px 6px 0 6px;
        margin: 0 auto;
  }

  @media (max-width: 1300px) {
    max-width: 1260px;
    justify-content: center;
  }`;