import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const getNotation = (prototype, shortlist, winner) => {
    let notation = '';

    if (prototype) {
        notation = 'Prototype';
    } else if (shortlist) {
        notation = 'Shortlist';
    } else if (winner) {
        notation = 'Winner'
    }

    return notation;
}

const getNotationSign = notation => (<InfoLabel>{notation}</InfoLabel>);

const Creative = forwardRef((props, ref) => {
    const {
        rowsCount,
        activeCreative,
        adInfo: {
            prototype, shortlist, winner, features, creativeId, name, industry, previewImgUrl
        },
    } = props;

    const isActive = activeCreative === creativeId;
    const notation = getNotation(prototype, shortlist, winner);

    const phoneNotationSign = rowsCount === 1 && notation ?
        getNotationSign(notation) : null;

    const desktopNotationSign = rowsCount > 1 && notation ?
        getNotationSign(notation) : null;

    return (
        <Link ref={ref}
            className={props.className}
            to={isActive ? '/' : `/${creativeId}`}>

            <Preview src={previewImgUrl} alt={`${name} creative`} />
            {phoneNotationSign}
            <Description>
                <Title>
                    {name} {desktopNotationSign}
                </Title>
                <TypeFormat>{industry} | {features.join(', ')}</TypeFormat>
                <LearnMore isActive={isActive} />
            </Description>
        </Link>
    )
})

const Title = styled.p`
    margin: 10px 0 0 15px;


@media (max-width: 880px) {
  margin-top: 6px;
  font-size: 16px;
  font-family: RobotoReg, sans-serif;
}`;

const TypeFormat = styled.p`
    margin: 10px 0 0 15px;
    font: 14px RobotoLight, sans-serif;

    @media (max-width: 880px) {
    
      max-width: 70%;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: RobotoLight, sans-serif;
    }`;

const InfoLabel = styled.span`
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      display: inline-block;
      padding: 10px 46px;
      background-color: rgba(32, 32, 32, 0.9);
      font-size: 14px;
      color: white;

${Title} & {
        position: static;
        display: inline-block;
        padding: 4px;
        margin-left: 10px;
        border-radius: 4px;
        background-color: white;
        text-transform: lowercase;
        font-size: 14px;
        color: black;
}
@media (max-width: 880px) {
 padding: 5px;
}`;

const Description = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: 64px;
    padding: 5px 0 15px 0;
    background-color: rgba(32, 32, 32, 0.9);
    color: white;
  @media (max-width: 880px) {
    width: 100%;
      min-height: 53px;
      height: auto;
      padding: 2px 0 3px 0;
  }`;

const LearnMore = styled.span`
    position: absolute;
    bottom: 30px;
    right: 6px;
    display: block;
    width: 25px;
    height: 25px;
    background: url("https://portfolio.loopme.com/media/content_box__icon.png") no-repeat;
    background-size: 100%;

 ${props => {
        if (props.isActive) return 'background-position: 0px -26px;'
    }}

  @media (max-width: 880px) {
    bottom: 28%; 
  }`;

const StyledCreative = styled(Creative)`
  position: relative;
  width: 396px;
  height: 310px;
  margin: 0 12px 25px 12px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 880px) {
    width: 100%;
    height: 220px;
    margin: 0 0 6px 0;
  }`;

const Preview = styled.img` 
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    ${StyledCreative}:hover & {
      transform: scale(1.1);
    }
    @media (max-width: 880px) {
      height: auto;
    }`;

export default StyledCreative
