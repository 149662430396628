import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const linkProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
};

const links = [
    { link: 'https://loopme.com/privacy', name: 'Privacy Policy' },
    { link: 'https://loopme.com/end-user-privacy-policy', name: 'End user Privacy' },
    { link: 'https://loopme.com/terms-conditions', name: 'Publisher Terms & Conditions' },
    { link: 'https://loopme.com/ad-serving-terms-conditions', name: 'Advertiser Terms & Conditions' },
    { link: 'https://loopme.com/program-policies', name: 'Program Policies' },
    { link: 'https://loopme.com/brand-safety-guidelines', name: 'Brand Safety Guidelines' },
];

const outboundLinks = links.map(({ link, name }, idx) => (
    <a key={idx} href={link} {...linkProps}>{name}</a>
));

export const Footer = () => (
    <StyledFooter>
        <Link to='/' target='_blank'>&#169;Copyright 2018 LoopMe</Link>
        {outboundLinks}
    </StyledFooter>
)

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  margin-top: 70px;
  background-color: rgba(32, 32, 32, 0.9);
  flex: 0 0 auto;

  a {
    font: 13px Roboto, sans-serif;
    color: #505050;

    &:hover {
      color: #796969;
    }
  }

/* mobile */
  @media (max-width: 880px) {
    margin-top: 0;
    display: block;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    height: 61px;
    padding: 10px 0;
    background-color: rgba(32, 32, 32, 0.9);
  }`;