import React from 'react';
import { Filter } from './Filter';
import { filtersList } from './filtersData';
import {StyledFiltersList} from './FilterStyles'

export const FiltersList = props => {

  const list = filtersList.map((filterInfo, i) => {
    return <Filter {...props} filterInfo={filterInfo} key={i} />
  });

  return (
    <StyledFiltersList isActive={props.isActive}>
      <div>
        {list}
      </div>
    </StyledFiltersList>
  );
};
