import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import TextInformation from './TextInformation';
import RightSide from './RightSide';

const Description = forwardRef((props, ref) => {
    let {
        rowsCount,
        adInfo: {
            prototype, clickUrl, shortlist, winner, compatibility, platform, features, name, qrLink,
        }
    } = props

    let textInformationModel = {
        name,
        features,
        platform,
        compatibility
    }

    const [isShowPopup, setShowPopup] = useState(false)
    const onLabelClick = () => setShowPopup(x => !x)
    const popup = rowsCount === 1 && isShowPopup ? getPopup({ isActive: isShowPopup, onLabelClick }) : null
    if (isShowPopup && rowsCount > 1) setShowPopup(false)
    const rightSide = {
        winner, shortlist, prototype, src: qrLink
        , showPopup: () => onLabelClick()
    }
    return (
        <StyledDescription ref={ref}>
            <TextInformation {...textInformationModel} />
            <RightSide {...rightSide} />
            {popup}
            <ShowDemo href={clickUrl} target="_blank">Demo</ShowDemo>
        </StyledDescription>)
})

const getPopup = (props, ref) => {

    return (
        <StyledPopup ref={ref} isActive={props.isActive} >
            <div >
                <p>This creative is a prototype example and has not been run as a live campaign.</p>
                <div onClick={props.onLabelClick}>
                    <span>Close</span>
                </div>
            </div>
        </StyledPopup>
    )
}

const StyledPopup = styled.div`
@media (max-width: 880px) {
display: ${props => props.isActive ? 'block' : 'none'};

position: absolute;
width: 100%;
height: 100%;
background-color: rgba(32, 32, 32, 0.9);

  &> div {
    display: block;
    position: absolute;
    top: 40px;
    left: 10%;
    width: 80%;
    border-radius: 10px;
    background-color: white;
    text-align: center;
    color: black;

    p {
      padding: 10px 20px;
    }

    & > div {
      padding: 20px;
      border-top: 1px solid #2f2f2f;
    }
  }
}`;


const ShowDemo = styled.a`
display: none;
@media (max-width: 880px) {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #ed1d24;
  text-align: center;
  color: white;

  &:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -4px 0;
    background: url("https://portfolio.loopme.com/media/mobile_assets/P_Demo_arrow.png") no-repeat;
    background-size: 13px;
    background-position: 6px 2px;
  }
}`;


const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 490px;
  height: 330px;
  padding: 10px 40px 40px 30px;
  background: url("https://portfolio.loopme.com/media/Detail_box.jpg") no-repeat;
  background-size: cover;
  font-family: RobotoLight, sans-serif;

@media (max-width: 880px) {
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  height: auto;
  padding: 10px 0 0 0;
}`;

export default Description