import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import { VideoView } from '../VideoView';
import withScrollTo from '../../HOC/withScrollTo';
import { BREAKPOINT_TABLET } from '../../constants/Breakpoints';

import CloseButton from './components/CloseButton';
import Description from './components/Description';

const CreativeView = (props) => {

    const { scrollTo, rowsCount, adInfo: { creativeId } } = props;

    const creativeView = useRef(null);

    useEffect(() => {
        const handleLoadEvent = () => {
            scrollTo(creativeView.current);
            window.removeEventListener('load', handleLoadEvent, false);
        }

        // requestAnimationFrame fixed a bug with animation
        document.readyState === 'complete' ?
            requestAnimationFrame(() => scrollTo(creativeView.current)) :
            window.addEventListener('load', handleLoadEvent, false);

    }, [creativeId, scrollTo, creativeView])

    const videoView = rowsCount > 1 && window.innerWidth >= BREAKPOINT_TABLET ?
        <VideoView adInfo={props.adInfo} rowsCount={rowsCount} /> : null;

    const flipMoveProps = {
        easing: 'ease-in-out',
        ref: creativeView,
        appearAnimation: true,
        duration: 250,
        delay: 150,
        staggerDurationBy: 150,
    };

    return (
        <StyledCreativeView {...flipMoveProps}>
            {videoView}
            <Description {...props} />
            <CloseButton />
        </StyledCreativeView>
    )
}

const StyledCreativeView = styled(FlipMove)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1260px;
  height: 680px;
  margin-bottom: 25px;
  background: #f4f4f4;
  border-top: 2px solid #ed1d24;
  color: white;

   @media (max-width: 880px) {
    height: auto;
    margin-bottom: 6px;
  }`;

export default withScrollTo(CreativeView);
