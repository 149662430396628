import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './styles/animations.css';
// import './styles/main.css';
// import './styles/mobile.css';


ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path='/' component={App} />
            <Route path='/:creativeId' component={App} />
        </Switch>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
