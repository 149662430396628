import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: RobotoReg;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"),
      url("https://portfolio.loopme.com/media/font/Roboto-Regular.woff2") format("woff2"), local("Roboto"),
      local("Roboto-Regular"), url("https://portfolio.loopme.com/media/font/Roboto-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: RobotoMedium;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
      url("https://portfolio.loopme.com/media/font/Roboto-Medium.woff2") format("woff2"), local("Roboto Medium"),
      local("Roboto-Medium"), url("https://portfolio.loopme.com/media/font/Roboto-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"),
      url("https://portfolio.loopme.com/media/font/Roboto-Light.woff2") format("woff2"), local("Roboto Light"),
      local("Roboto-Light") url("https://portfolio.loopme.com/media/font/Roboto-Light.ttf") format("truetype");
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    font-family: RobotoReg, sans-serif;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    text-decoration: none;
  }

  #root {
    min-height: 100%;
    height: 100%;
  }

  .anim-appear {
    opacity: 0.01;
  }

  .anim-appear.anim-appear-active {
    opacity: 2;
    transition: opacity 5s ease-in;
  }

  .creative-view-animation-appear {
    opacity: 0.01;
    height: 0;

    &.creative-view-animation-appear-active {
      opacity: 2;
      height: 680px;
      transition: 5s ease-in all;
    }
  }

  .load-more {
    width: 234px;
    margin: 40px auto 0 auto;
    padding: 10px;
    border: 1px solid #ed2027;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    color: #ed2027;

    span:after {
      content: "";
      display: inline-block;
      width: 23px;
      height: 14px;
      background: url("https://portfolio.loopme.com/media/Learn_more_icon.png") 8px 1px no-repeat;
      background-size: contain;
    }

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 880px) {
      width: 99%;
      margin: 0 auto 20px auto;
      padding: 10px 0;
    }
  }`;