import React, { useState, useEffect } from 'react';
import WindowSizeListener from 'react-window-size-listener';
import styled from 'styled-components';

import Banner from '../components/Banner';
import { CreativeList } from '../components/creatives/CreativesList';
import { FiltersList } from '../components/filters/FiltersList';
import MobileMenu from '../components/MobileMenu';
import { Footer } from '../components/Footer';
import { resizeWatcher } from '../components/ResizeWatcher';
import { getMobileOS } from '../utils';

import { GlobalStyles } from '../components/basics/globalStyles';

import { useParams } from 'react-router-dom';

const mobileOS = getMobileOS();
const asyncRawAdsListData = new Promise((resolve, reject) => {
    fetch('/data.json').then(x => x.json().then(resolve)).catch(reject);
});

const mobileFilter = (asyncData) => new Promise(async (resolve) => {
    const data = await asyncData;
    resolve(mobileOS ? data.filter(({ compatibility }) => compatibility.includes(mobileOS)) : data)
});
const asyncAdsListData = mobileFilter(asyncRawAdsListData)

const App = (props) => {
    const [rowsCount, setRowsCount] = useState(3);
    const [isFiltersActive, toggleFilters] = useState(false);

    const [adsList, setAdsList] = useState([]);

    const [activeCreative, setActiveCreative] = useState(null);
    const removeActiveCreative = () => setActiveCreative(null);

    const [activeIndustries, setIndustries] = useState([]);
    const addIndustry = (option) => setIndustries(e => [...e, option]);
    const deleteIndustry = (option) => setIndustries(e => e.filter(opt => opt !== option));

    const [activeFeatures, setFeatures] = useState([]);
    const setFeature = (option) => setFeatures(e => [...e, option]);
    const deleteFeature = (option) => setFeatures(e => e.filter(opt => opt !== option));


    const { creativeId } = useParams();
    useEffect(() => {
        if (creativeId && activeCreative !== creativeId) {
            console.log('set ', creativeId);
            setActiveCreative(parseFloat(creativeId))
        }
        else if (!creativeId && activeCreative) {
            console.log('remove ', activeCreative);
            removeActiveCreative()
        }
    }, [creativeId, activeCreative]);

    useEffect(() => {
        asyncAdsListData.then(setAdsList);
    }, [asyncAdsListData])

    const filtersInfo = {
        activeIndustries,
        activeFeatures,
        setIndustry: addIndustry,
        deleteIndustry,
        setFeature,
        deleteFeature,
        removeActiveCreative,
        isActive: isFiltersActive
    };

    const creativesListInfo = {
        activeCreative,
        adsList,
        history: props.history,
        activeIndustries,
        activeFeatures,
        rowsCount,
    };

    return (
        <StyledMainWrapper>
            <GlobalStyles />
            <WindowSizeListener onResize={windowSize => {
                resizeWatcher(windowSize, setRowsCount);
            }} />

            <div>
                <Banner />
                <MobileMenu onMenuClick={toggleFilters} />
                <FiltersList {...filtersInfo} />
                <CreativeList  {...creativesListInfo} />
            </div>

            <Footer />
        </StyledMainWrapper>
    );
};



const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > div {flex: 1 0 auto; }`;

export default App;