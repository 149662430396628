import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CloseButton = forwardRef((props, ref) => {
    return (<StyledCloseButton ref={ref} to='/' />)
})

const StyledCloseButton = styled(Link)`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background: url("https://portfolio.loopme.com/media/X_icon.png") no-repeat;
    background-size: 100%;

    &:hover {
      cursor: pointer;
    }
      
  @media (max-width: 880px) {
    display: none;

  }
`;

export default CloseButton