export const filtersList = [
    {
        filterName: 'Industry',
        optionsList: [
            'QSR',
            'Retail',
            'Services',
            'Tech',
            'Travel',
            'Auto',
            'Entertainment',
            'Finance',
            'FMCG / CPG',
            'Luxury',
            'Pharma'
        ]
    },
    {
        filterName: 'Features',
        optionsList: [
            'Embedded Video',
            'Store Locator',
            'Animation',
            'Poll',
            '360 view (Object and Images)',
            'Gallery (Videos and Images)',
            'Calendar',
            'Tilt/Shake',
            'Parallax',
            'Wipe',
            'Camera',
            'Gamification',
            'Drag & Drop',
            'Form',
            'Generic Rich Media (Mosaic unit etc)',
            'Colour Recognition',
            'Vibration',
            'Charge level',
            'Shoppable'
        ]
    }
]