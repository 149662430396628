import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const getOffset = el => {
    const rect = el.getBoundingClientRect();
    const { pageXOffset, pageYOffset } = window;
    return { top: rect.top + pageYOffset, left: rect.left + pageXOffset, rect };
};

export default function withScrollTo(WrappedComponent) {
    return class extends Component {
        scrollTo = elem => {
            const node = ReactDOM.findDOMNode(elem);
            
            if(node){
                const offset = getOffset(node);
                const alightOffset = (window.innerHeight - offset.rect.height) / 2;
                const top = offset.top - alightOffset;
                window.scrollTo({ top, behavior: 'smooth' });
            }
        }

        render() {
            return <WrappedComponent scrollTo={this.scrollTo} {...this.props} />
        }
    }
}
