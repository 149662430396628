import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

export const MobileMenu = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const onMenuClick = () => {
        setIsActive(s => !isActive);
        props.onMenuClick(!isActive);
    };

    useEffect(() => {
        onMove()
        componentDidMount()
        return componentWillUnmount
    })

    const onMove = () => window.scrollY > 0 ? setIsMoved(true) : setIsMoved(false);

    const componentDidMount = () => {
        window.addEventListener('scroll', onMove);
    }

    const componentWillUnmount = () => {
        window.removeEventListener('scroll', onMove);
    }

    return (
        <StyledMobileMenu isActive={isActive} isMoved={isMoved} onClick={onMenuClick}>
            <HiddeScroll isActive={isActive} />
            <Logo isActive={isActive || isMoved}>
                <img src='./media/mobile_assets/P_logo_on_menubar.png' alt='LoopMe logo' />
            </Logo>
            <FiltersButton isActive={isActive} isMoved={isMoved} />
        </StyledMobileMenu>
    )
}

const HiddeScroll = createGlobalStyle`
  body {
    overflow:${props => props.isActive ? 'hidden' : 'visible'};
    position:${props => props.isActive ? 'fixed' : 'static'};  
  }`;

const ACTIVE = 'https://portfolio.loopme.com/media/mobile_assets/P_Filter_tick_on_filterpage.png';
const MOVED = 'https://portfolio.loopme.com/media/mobile_assets/P_Filter_default_on_menubar.png';
const DEFAULT = 'https://portfolio.loopme.com/media/mobile_assets/P_Filter_defualt.png';
const chooseBg = (props) => {
    let src = DEFAULT;
    src = props.isMoved ? MOVED : src;
    src = props.isActive ? ACTIVE : src;
    return src;
}

const FiltersButton = styled.button`
  background: url('${props => chooseBg(props)}') no-repeat;

  width: 5vh;
  height: 5vh;
  margin-right: 10px;
  background-size: 100%;
  background-position: 0 2px;
  border: none;
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
    border: none;
  }`;


const Logo = styled.figure`
  opacity: ${props => props.isActive ? 1 : 0};
  width: auto;
  height: 8vh;
  margin-left: 10px;

  img {
    width: auto;
    height: 5vh;
    margin-top: 11px;
  }`;

const StyledMobileMenu = styled.div`
    display: none;

  @media (max-width: 880px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    right: 0;
    width: 100%;
    height: 8vh;
    opacity: 0.95;
    align-items: center;

    position: ${props => props.isMoved ? 'fixed' : 'absolute'}; 
    top: ${props => props.isMoved ? '0' : '4px'}; 
    border-top: ${props => props.isMoved ? '4px solid #ed1d24' : 'initial'};
    border-bottom: ${props => props.isActive || props.isMoved ? '1px solid #a0a0a0' : 'initial'}; 
    background: ${props => props.isActive || props.isMoved ? 'white' : 'initial'}; 
    z-index: ${props => props.isActive || props.isMoved ? '7' : 'initial'}; 
}`;

export default MobileMenu;