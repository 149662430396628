import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Qr from './Qr';

const RightSide = forwardRef((props, ref) => {
    const { winner, shortlist, prototype, showPopup, src } = props

    return (
        <StyledRightSide ref={ref}>
            <Info>
                <Badges {...{ winner, shortlist }} />
                <Prototype
                    onClick={showPopup}
                    className={`prototype ${prototype ? 'active' : ''}`}>
                    <p className='question'>Prototype</p>
                    <Tooltip className='tooltip'>
                        This creative is a prototype example and has not been run as a live campaign.
          </Tooltip>
                </Prototype>
            </Info>
            <Qr src={src} />

        </StyledRightSide>)
})


const StyledBadges = styled.div`
  max-width: 140px;
  font-size: 18px;
  position: relative;
  left: 9px;
  display: block;

  p {
      padding: 0 0 15px 20px;
      margin: 0;
      margin-top: -3px;

      &:after {
        content: "";
      display: inline-block;
      width: 40px;
      height: 31px;
      position: relative;
      top: 11px;
      background: url("https://portfolio.loopme.com/media/shortlist.png");
      background-repeat: no-repeat;
      background-position: 11px 0px;
      background-size: 23px 30px;
      }
    }`;

const Badges = (props) => {
    const avilableBages = []

    if (props.shortlist) avilableBages.push('Shortlist')
    if (props.winner) avilableBages.push('Winner')

    const renderedBadjes = avilableBages.map((x, i) => (<p key={i}><span>{x}</span></p>))
    return (
        <StyledBadges>
            {renderedBadjes}
        </StyledBadges>)
}

const Tooltip = styled.div`
    display: none;
    position: relative;
    top: 7px;
    left: -90px;
    width: 217px;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    color: black;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: -10px;
      left: 106px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
`;

const Prototype = styled.div`
  display: none;
  max-width: 140px;
  position: relative;
  top: -3px;
  left: 10px;
  font-size: 18px;

  &.active,
  &:hover .tooltip {
    display: block;
  }

  .question {
    margin: 0;

    &::after {
      content: "";
      display: inline-block;
      width: 31px;
      height: 31px;
      position: relative;
      top: 11px;
      background: url("https://portfolio.loopme.com/media/prototype.png");
      background-repeat: no-repeat;
      background-position: 8px 3px;
      background-size: 20px 21px;
    }
  }

  @media (max-width: 880px) {
  &:hover .tooltip {
    display: none;
  }
  }`;


const StyledRightSide = styled.div`
  align-self: flex-end;

@media (max-width: 880px) {
  align-self: flex-end;
  top: 13px;
  position: absolute;
}`;

const Info = styled.div`
  width: 133px;
  height: 110px;
`;

export default RightSide