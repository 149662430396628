import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } from '../constants/Breakpoints';

export const resizeWatcher = (windowSize, setRowsCount) => {
    const isDesktop = windowSize.windowWidth >= BREAKPOINT_DESKTOP;
    const isTablet = windowSize.windowWidth < BREAKPOINT_DESKTOP && windowSize.windowWidth >= BREAKPOINT_TABLET;

    if (isDesktop) {
        return setRowsCount(3);
    } else if (isTablet) {
        return setRowsCount(2);
    }

    setRowsCount(1);
};
